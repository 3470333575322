import React from 'react';
import PageNotFoundImage from 'assets/images/PageNotFoundImage.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <Container fluid="lg">
      <Row className="justify-content-center">
        <Col xs={12} md={6} className="d-flex flex-column">
          <LazyLoadImage src={PageNotFoundImage} />
          <Link to="/" className="btn btn-primary d-block px-4 align-self-center">
            {t('navigation.home').toUpperCase()}
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
